/**
 * Process
 */
export class Process {
    /** actionType */
    public actionType: string;
    /** processId */
    public processId: string;
    /** parameters */
    public parameters: Object;
    /** stepName */
    public stepName: string;
    /** umAccountNumber  */
    public umAccountNumber?: string;

    public constructor (actionType: string,
        processId: string,
        stepName?: string,
        umAccountNumber?: string,
        parameters?: Object,
    ) {
        this.actionType = actionType;
        this.processId = processId;
        this.parameters = parameters;
        this.stepName = stepName;
        this.umAccountNumber = umAccountNumber;

    }
}
