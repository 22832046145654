import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ACCESS_LEVELS_LIST, CONTRACT_TYPES_LIST } from '../../constants/Defines';
import { TranslateService } from '@ngx-translate/core';
import { RoutesUtilsService } from '@vfde-fe/ngx-lib/services/routes-utils';

/** UnauthorizedComponent */
@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.css'],
})
export class UnauthorizedComponent implements OnInit {
  /** to hold the notifaction details to bindind in the notification */
  public notificationDetails: any = {};
  /** to hold the contractType from the querystring */
  public contractType: string;
  /** to hold the accessLevel from the querystring */
  public accessLevel: string;
  /** to be true if the contractType was undefined or was defined but not included within the UNAUTHORIZED_CONTRACT_TYPES */
  public isGeneral: boolean;
  /** pageTitle */
  public pageTitle: string;
  /** List of the possible unauthorized contract types */
  public UNAUTHORIZED_CONTRACT_TYPES = [
      'peter',
      'bart',
      'emma',
      'donald',
      'della',
      'daisy',
      'ralf',
  ];

  /** seamless login:opens tray overlay in seamless login */
  public trayOpened = false;

public constructor (private route: ActivatedRoute, private ts: TranslateService, private routesUtilsService: RoutesUtilsService) { }
 /** ngOnInit */
  public ngOnInit () {
    this.route.queryParams
      .subscribe(params => {
          if (this.contractType == CONTRACT_TYPES_LIST.DONALD
            || this.contractType == CONTRACT_TYPES_LIST.DAISY || this.contractType == CONTRACT_TYPES_LIST.DELLA) {
                return '';
            }
         this.contractType = this.contractType ? this.contractType :  params.contractType;
         this.accessLevel = this.accessLevel ? this.accessLevel : params.accessLevel;
         this.pageTitle = this.pageTitle ? this.pageTitle : params.pageTitle;
         // check if general
         this.isGeneral = this.contractType ? (this.UNAUTHORIZED_CONTRACT_TYPES.indexOf(this.contractType) > -1 ? false : true) : true;
         this.contractType = this.isGeneral ? 'general' : this.UNAUTHORIZED_CONTRACT_TYPES.find(x => x === this.contractType);
         // check if access level list is given; if given: check if it's from list: if yes find it, else make it contract - if not given: make it contract
         this.accessLevel = this.accessLevel ? ((this.accessLevel === ACCESS_LEVELS_LIST.CONTRACT || this.accessLevel === ACCESS_LEVELS_LIST.SUBSCRIPTION || this.accessLevel === ACCESS_LEVELS_LIST.CONTRACT_OR_SUBSCRIPTION || this.accessLevel === ACCESS_LEVELS_LIST.ACCOUNT) ? this.accessLevel : 'contract') : 'contract';
         this.notificationDetails = {
              headline: this.ts.instant('unauthorized.' + this.contractType + '.' + (this.isGeneral ? (this.accessLevel + '.') : '') + 'std-headline'),
              text: this.ts.instant('unauthorized.' + this.contractType + '.' + (this.isGeneral ? (this.accessLevel + '.') : '') + 'text-body'),
              class: 'error',
              iconClass: 'i-red',
              icon: 'i-group-error-circle-sml',
              links: this.contractType == CONTRACT_TYPES_LIST.PETER || this.contractType == CONTRACT_TYPES_LIST.DONALD
                  || this.contractType == CONTRACT_TYPES_LIST.DAISY || this.contractType == CONTRACT_TYPES_LIST.DELLA ? [{
                  title: this.ts.instant('unauthorized.' + this.contractType + '.link.std-headline'),
                  url: this.ts.instant('unauthorized.' + this.contractType + '.link.link-href'),
                  target: this.ts.instant('unauthorized.' + this.contractType + '.link.opt-target'),
              }] : [],
            };

      });
  }
  /** seamless login: open overlay */
  public openOverlay () {
    this.trayOpened = true;
    const { currentURL } = this.routesUtilsService.getHistoryUrl();
    this.routesUtilsService.navigateToURL(currentURL);
  }

}
