/* eslint-disable */
export var config = {
    home: {
        name: 'home',
        route: '/home'
    },
    landingPageModule: {
        name: 'dokument-hochladen',
        route: '/dokument-hochladen',
        navigation: {
            topLink: true,
            mobileBtn: true,
            navigationLink: '/login'
        },
        tracking: {
            trackingType: 'page',
            trackingTags: {
                siteStructure: ['central landing page'],
                siteArea: 'meinvodafone',
                module: 'central landing page',
            },
            transactionState: 'start'
        }
    },
    registration: {
        name: 'registrierung',
        route: '/registrierung',
        navigation: {
            topLink: true,
            mobileBtn: true,
            navigationLink: '/login'
        },
        notifyBeforeUnload: true,
        processKey: 'com.uxpsystems.mint.vodafone.process.frontend.registration.v1.0',
        pageType: 'oneTimeTransaction',
        chooseRegistrationType: {
            name: 'registrierungsart',
            route: '/registrierung/registrierungsart',
            stepName: 'choose-registration-type',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['register user', 'identify user type'],
                    siteArea: 'meinvodafone',
                    module: 'register user',
                    step: 1,
                    stepName: 'identify user type',
                    status: ['start'],
                    hasOApplication: false
                }
            },
            navigation: {
                topLink: false,
                mobileBtn: false,
                navigationLink: '/login'
            }
        },
        chooseContractType: {
            name: 'vertragstyp',
            route: '/registrierung/vertragstyp',
            stepName: 'choose-contract-type',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['register user', 'choose contract type'],
                    siteArea: 'meinvodafone',
                    module : 'register user',
                    step : 2,
                    stepName : 'choose contract type',
                    status : [],
                    hasOApplication: false,
                }
            },
            navigation: {
                topLink: false,
                mobileBtn: false,
                navigationLink: '/login'
            }
        },

        submitPersonalData: {
            name: 'persoenliche_daten',
            route: '/registrierung/persoenliche_daten',
            stepName: 'submit-personal-data',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['register user', 'edit userdata', 'personal data'],
                    module: 'register user',
                    step : 6,
                    stepName : 'personal data',
                    status : [],
                    hasOApplication: false
                }
            }
        },
        submitMobileIdentifier: {
            name: 'mobilvertrag',
            route: '/registrierung/mobilvertrag',
            stepName: 'submit-mobile-identifier',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['register user', 'identify contract', 'enter mobile credentials'],
                    module: 'register user',
                    step : 3,
                    stepName : 'enter mobile credentials',
                    status : [],
                    hasOApplication: false
                }
            }
        },
        submitCableCredentials: {
            name: 'aktivierungscode',
            route: '/registrierung/aktivierungscode',
            stepName: 'submit-cable-credentials',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['register user', 'identify contract', 'enter vkd contract'],
                    siteArea: 'meinvodafone',
                    module: 'register user',
                    step : 3,
                    stepName : 'enter vkd credentials',
                    status : [],
                    hasOApplication: false
                }
            }
        },
        submitDslCredentials: {
            name: 'onlineregcode',
            route: '/registrierung/onlineregcode',
            stepName: 'submit-dsl-registration-code',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['register user', 'identify contract', 'enter dsl contract'],
                    siteArea: 'meinvodafone',
                    module: 'register user',
                    step : 3,
                    stepName : 'enter dsl credentials',
                    status : [],
                    hasOApplication: false
                }
            }
        },

        submitUnityMediaData: {
            name: 'kabelvertrag',
            route: '/registrierung/kabelvertrag',
            stepName: 'enter-um-data',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['register user', 'identify contract', 'enter UM data'],
                    siteArea: 'meinvodafone',
                    module: 'register user',
                    step : 3,
                    stepName : 'enter UM data',
                    status : [],
                    hasOApplication: false
                }
            }
        },
        submitBanCredentials: {
            name: 'vertragsdaten',
            route: '/registrierung/vertragsdaten',
            stepName: 'submit-ban-credentials'
        },
        submitSubscriberCredentials: {
            name: 'teilnehmerdaten',
            route: '/registrierung/teilnehmerdaten',
            stepName: 'submit-subscriber-credentials',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['register user', 'identify contract', 'enter mobile contract', 'password & mtan verification'],
                    siteArea: 'meinvodafone',
                    module: 'register user',
                    step : 4,
                    stepName : 'password & mtan verification',
                    status : [],
                    hasOApplication: false
                }
            }
        },
        submitContactData: {
            name: 'kontaktdaten',
            route: '/registrierung/kontaktdaten',
            stepName: 'submit-contact-data',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['register user', 'edit userdata', 'contact data'],
                    siteArea: 'meinvodafone',
                    module: 'register user',
                    step : 7,
                    stepName : 'contact data',
                    status : [],
                    hasOApplication: false
                }
            }
        },
        submitUserData: {
            name: 'accountdaten',
            route: '/registrierung/accountdaten',
            stepName: 'submit-user-data',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['register user', 'edit userdata', 'enter username + pw'],
                    siteArea: 'meinvodafone',
                    module: 'register user',
                    step : 8,
                    stepName : 'enter username + pw',
                    status : [],
                    hasOApplication: false
                }
            }
        },
        reviewData: {
            name: 'uebersicht',
            route: '/registrierung/uebersicht',
            stepName: 'review-data',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['register user', 'edit userdata', 'review account data'],
                    siteArea: 'meinvodafone',
                    module: 'register user',
                    step : 9,
                    stepName : 'review account data',
                    status : [],
                    hasOApplication: false
                }
            }
        },
        accountConfirmation: {
            name: 'bestaetigung',
            route: '/registrierung/bestaetigung',
            confirmationPage: true,
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['register user', 'edit userdata', 'confirmation account setup'],
                    siteArea: 'meinvodafone',
                    module: 'register user',
                    step : 10,
                    stepName : 'confirmation account setup',
                    status : [],
                    hasOApplication: false
                }
            },
            navigation: {
                topLink: false,
                mobileBtn: false
            }
        },
        renameContract: {
            name: 'vertrag_umbenennen',
            route: '/registrierung/vertrag_umbenennen',
            stepName: 'enter-mobile-name',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    module: 'register user',
                    siteStructure: ['register user', 'edit mbo data', '{{siteStructureRenameContract}}'],
                    step: 5,
                    stepName : '{{stepNameRenameContractRegistration}}',
                    status : [],
                    hasOApplication: false
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.skip.std-headline',
                navigationLink: '/login'
            }
        },
        addUnityMediaName: {
            name: 'vertragsnameum',
            route: '/registrierung/vertragsnameum',
            stepName: 'enter-um-name',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['register user', 'edit mbo data', 'edit UM or vkd'],
                    siteArea: 'meinvodafone',
                    module: 'register user',
                    step: 4,
                    stepName : 'edit UM or vkd',
                    status : [],
                    hasOApplication: false
                }
            }
        },
        addKdDslContractName: {
            name: 'vertragsname',
            route: '/registrierung/vertragsname',
            stepName: 'enter-dsl-name',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['register user', 'edit mbo data', 'edit dsl or vkd'],
                    siteArea: 'meinvodafone',
                    module: 'register user',
                    step: 11,
                    stepName : 'edit dsl or vkd',
                    status : [],
                    hasOApplication: false
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.skip.std-headline',
                navigationLink: '/login'
            }
        },
        addCableContractName: {
            name: 'cablename',
            route: '/registrierung/vertragsname',
            stepName: 'enter-cable-name',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['register user', 'edit mbo data', 'edit dsl or vkd'],
                    siteArea: 'meinvodafone',
                    module: 'register user',
                    step: 5,
                    stepName : 'edit dsl or vkd',
                    status : [],
                    hasOApplication: false
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.skip.std-headline',
                navigationLink: '/login'
            }
        },
        renameContractConfirmation: {
            name: 'vertraghinzugefuegt',
            route: '/registrierung/vertraghinzugefuegt',
            confirmationPage: true,
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'registration',
                    step: 12,
                    stepName: 'confirmation',
                    status: ['end', 'success'],
                    siteStructure: ['register user', 'edit mbo data', 'confirmation'],
                    siteArea: 'meinvodafone',
                    outcome: '{{outcomeRenameContractConfirmation}}',
                    type: 'myvodafone:transaction',
                    hasOApplication: false

                }
            },
            navigation: {
                topLink: true,
                mobileBtn: false,
                mobileBtnLabelKey: 'common.buttons.goToMeinVodafone.std-headline'
            }
        },
        validateEmail: {
            name: 'validate_email',
            route: '/registrierung/validate_email',
            stepName: 'validate-email'
        },
        registrationMtan: {
            name: 'smscode',
            route: '/registrierung/smscode',
            stepName: 'validate-mTan',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['register user', 'identify contract', 'enter mobile contract', 'password & mtan verification'],
                    siteArea: 'meinvodafone',
                    module: 'register user',
                    step : 4,
                    stepName : 'password & mtan verification',
                    status : [],
                    hasOApplication: false
                }
            }
        },
        requestRegCode: {
            name: 'registration_code',
            route: '/registrierung/registration_code',
            stepName: 'DSL-Online-Registration-Code',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    loginStatus : "not logged in",
                    pageType : "self service",
                    platformType : "responsive",
                    seamlessLogin : false,
                    siteArea: 'meinvodafone',
                    siteStructure: ['register user' ,'enter dsl credentials' ,'request registration code' ],
                    module: 'register user',
                    hasOApplication:false,
                    type :'ecare',
                    status:[],
                    step: 4,
                    stepName : "request registration code",
                }},
        },
        forgetCellNumber: {
            name: 'forget_cell_number',
            route: '/registrierung/forget_cell_number',
            stepName: 'DSL-Online-Registration-Code ',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    loginStatus : "not logged in",
                    pageType : "self service",
                    platformType : "responsive",
                    seamlessLogin : false,
                    siteArea: 'meinvodafone',
                    siteStructure: ['register user' ,'enter dsl credentials' ,'phone number unknown' ],
                    module: 'register user',
                    hasOApplication:false,
                    formtype :'ecare',
                    status:["end","failure"],
                    step: 5,
                    stepName : "phone number unknown",
                }},
        }
    },
    login: {
        name: 'login',
        route: '/login',
        navigation: {
            topLink: false,
            mobileBtn: true
        },
        reset: {
            name: 'zugangsdaten_vergessen',
            route: '/login/zugangsdaten_vergessen',
            titleKey: 'loginModule.resetCredential.std-headline',
            tracking: {
                trackingType: 'page',
                trackingTags: {
                    siteStructure: ['login', 'forgotten login data', 'choose credential'],
                    siteArea: 'meinvodafone',
                    module: 'forgotten login data'
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: '/login'
            }
        },
        seamless: {
            name: 'seamless/info',
            route: '/seamless/info'
        },
        enterContactData: {
                name: 'firstlogin',
                route: '/login/firstlogin',
                titleKey: 'loginModule.firstLogin.std-headline',
                stepName: 'enter-contact-data',
                notifyBeforeUnload: true,
                navigation: {
                    topLink: true,
                    mobileBtn: true,
                    navigationLink: '/login'
                },
                tracking: {
                    trackingType: 'transaction',
                    trackingTags: {
                        siteStructure: ['register user', 'first login', 'enter contact data'],
                        siteArea: 'meinvodafone',
                        module: 'first login',
                        step: 1,
                        stepName: 'personal data',
                        status: ['start'],
                        context: '{{contextEnterContactDataFirstLogin}}'
                    }
                }
        },
        enterLoginData: {
                name: 'firstlogin-accountdaten',
                route: '/login/firstlogin-accountdaten',
                titleKey: 'loginModule.firstLogin.std-headline',
                stepName: 'enter-login-data',
                navigation: {
                    topLink: true,
                    mobileBtn: true,
                    navigationLink: '/login'
                },
                tracking: {
                    trackingType: 'transaction',
                    trackingTags: {
                        siteStructure: ['register user', 'first login', 'enter username + pw'],
                        siteArea: 'meinvodafone',
                        module: 'first login',
                        step: 2,
                        stepName: 'user data',
                        status: []
                    }
                }
        },
        accountConfirmation: {
                name: 'firstlogin-bestaetigung',
                route: '/login/firstlogin-bestaetigung',
                titleKey: 'loginModule.firstLogin.std-headline',
                stepName: 'confirm-data',
                confirmationPage: true,
                tracking: {
                    trackingType: 'transaction',
                    trackingTags: {
                        siteStructure: ['register user', 'first login', 'confirmation userdata'],
                        siteArea: 'meinvodafone',
                        module: 'first login',
                        step: 3,
                        stepName: 'confirmation',
                        status: ['end', 'success']
                    },
                    transactionState: 'end'
                },
                navigation: {
                    topLink: true,
                    mobileBtn: false,
                    mobileBtnLabelKey: 'common.buttons.goToMeinVodafone.std-headline'
                }
        },
        updateEmail: {
            name: 'email_bestaetigen',
            route: '/login/email_bestaetigen',
            stepper: [
                {
                    name: 'step1',
                    stepName: 'update-email',
                    tracking: {
                        trackingType: 'transaction',
                        trackingTags: {
                            siteStructure: ['login', 'force e-mail verification', 'validate email'],
                            siteArea: 'meinvodafone',
                            module: 'checkEmailValidation',
                            step: 1,
                            stepName: 'validate email',
                            status: ['start'],
                            hasOApplication: false
                        }
                    },
                },
                {
                    name: 'step2',
                    stepName: 'validate-otp',
                    tracking: {
                        trackingType: 'transaction',
                        trackingTags: {
                            siteStructure: ['login', 'force e-mail verification', 'validate otp'],
                            siteArea: 'meinvodafone',
                            module: 'checkEmailValidation',
                            step: 2,
                            stepName: 'validate otp',
                            status: [''],
                            hasOApplication: false
                        }
                    },
                },
            ],
            fallbackDeeplinking: {
                url: '/login',
                disableRefresh: true,
            },
        },
        confirmation: {
            name: 'email-verifivation-confirmation',
            route: '/login/email-verifivation-confirmation',
            confirmationPage: true,
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['login', 'force e-mail verification', 'confirmation'],
                    siteArea: 'meinvodafone',
                    module: 'checkEmailValidation',
                    step: 3,
                    stepName: 'confirmation',
                    status: ['end','success'],
                    hasOApplication: false
                }
            },
            fallbackDeeplinking: {
                url: '/login',
                disableRefresh: true,
            },
        },
    },
    forgetPassword: {
        name: 'passwort',
        route: '/passwort',
        titleKey: 'forgetPassword.std-headline',
        processKey: 'com.uxpsystems.mint.vodafone.process.frontend.resetPasswordRequest',
        navigation: {
            topLink: true,
            mobileBtn: true,
            navigationLink: '/login'
        },
        notifyBeforeUnload: true,
        submitResetPasswordToken: {
            name: 'submitResetPasswordToken',
            route: '/passwort/submitResetPasswordToken',
            stepName: 'submit-reset-password-token'
        },
        changePassword: {
            name: 'passwort_aendern',
            route: '/passwort/passwort_aendern',
            navigation: {
                topLink: true,
                mobileBtn: true,
            },
            stepName: 'validate-otp',
            stepper: [
                {
                    name: 'step1',
                    stepName: 'validate-otp',
                    tracking: {
                        trackingType: 'transaction',
                        trackingTags: {
                            siteStructure: ['login', 'forgotten login data', 'validate otp', 'start'],
                            siteArea: 'meinvodafone',
                            module: 'forgotten login data new password',
                            step: 1,
                            stepName: 'validate otp',
                            status: ['start'],
                            hasOApplication: false
                        }
                    },
                },
                {
                    name: 'step2',
                    stepName: 'change-password',
                    tracking: {
                        trackingType: 'transaction',
                        trackingTags: {
                            module: 'forgotten login data new password',
                            step: 2,
                            siteStructure: ['login', 'forgotten login data', 'set password', 'start'],
                            siteArea: 'meinvodafone',
                            stepName: 'set password',
                            context: '{{contextDynamicValue}}',
                            status: ['start'],
                            hasOApplication: false
                        },
                        transactionState: 'start'
                    }
                },
            ],
        },
        changePasswordConfirm: {
            name: 'passwort_bestaetigt',
            route: '/passwort/passwort_bestaetigt',
            titleKey: 'forgetPassword.changePasswordConfirm.std-headline',
            confirmationPage: true,
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'forgotten login data new password',
                    step: 2,
                    siteStructure: ['login', 'forgotten login data', 'set password', 'confimation'],
                    siteArea: 'meinvodafone',
                    stepName: 'confirmation',
                    status: ['end', 'success'],
                    hasOApplication: false
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: false,
                mobileBtnLabelKey: 'common.buttons.back.std-headline',
                navigationLink: '/login'

            }
        },
        alternativeMethods: {
            name: 'methode',
            route: '/passwort/methode',
            stepName: 'alternative-password-recovery-choice',
            titleKey: 'forgetPassword.validateEmail.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['login',"forgot internet password", "recovery method"],
                    siteArea: 'meinvodafone',
                    module: 'forgot internet password',
                    stepName: 'recovery method',
                    step: 2,
                    hasOApplication: false
                },
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.cancel.std-headline',
                navigationLink: '/login'

            }
        },
        chooseContract: {
            name: 'vertragsauswahl',
            route: '/passwort/vertragsauswahl',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['login', 'forgotten login data', 'choose alternative methode', 'choose contract type'],
                    siteArea: 'meinvodafone'
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: false,
                mobileBtnLabelKey: 'common.buttons.back.std-headline',
                navigationLink: '/login'
            }
        },
        validateEmail: {
            name: 'email_bestaetigen',
            route: '/passwort/email_bestaetigen',
            titleKey: 'forgetPassword.validateEmail.std-headline',
            stepName: 'reset-password-init',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'forgot internet password',
                    step: 1,
                    siteStructure: ['login', 'forgot internet password', 'enter user name'],
                    siteArea: 'meinvodafone',
                    stepName: 'enter user name',
                    status: ['start'],
                    hasOApplication: false,
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: '/login/zugangsdaten_vergessen'
            }
        },
        recoverNotPossible: {
            name: 'nicht_moeglich',
            route: '/passwort/nicht_moeglich',
            titleKey: 'forgetPassword.recoverNotPossible.std-headline',
            stepName: 'recover-not-possible',
            notifyBeforeUnload: false,
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['login',"forgot internet password", "recovery not possible"],
                    siteArea: 'meinvodafone',
                    stepName: 'recovery not possible',
                    status: ["end","failure"],
                    module: 'forgot internet password',
                    step: 2,
                    hasOApplication: false,
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.close.std-headline',
                navigationLink: '/passwort/email_bestaetigen'
            },
            fallbackDeeplinking: {
                url: '/meinvodafone/account/passwort/email_bestaetigen',
                disableRefresh: false
            }
        },
        mtanValidation: {
            name: 'validate_mTan',
            route: '/passwort/validate_mTan',
            titleKey: 'forgetPassword.chooseMethod.std-headline',
            stepName: 'validate-mTan',
            notifyBeforeUnload: true,
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['login',"forgot internet password", "recovery via mtan"],
                    module: 'forgot internet password',
                    siteArea: 'meinvodafone',
                    stepName: 'recovery via mtan',
                    step: 3,
                    hasOApplication: false
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.back.std-headline',
                navigationLink: '/login'
            },
            fallbackDeeplinking: {
                url: '/meinvodafone/account/passwort/email_bestaetigen',
                disableRefresh: false
            }
        },
        emailConfirmation: {
            name: 'emailConfirmation',
            route: '/passwort/emailConfirmation',
            titleKey: 'forgetPassword.confirmEmailValidation.std-headline',
            notifyBeforeUnload: false,
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['login',"forgot internet password", "confirmation via email"],
                    siteArea: 'meinvodafone',
                    module: 'forgot internet password',
                    step: 4,
                    stepName: 'confirmation via email',
                    status: ["end","success"],
                    hasOApplication: false
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.back.std-headline',
                navigationLink: '/login'
            },
            fallbackDeeplinking: {
                url: '/login',
                disableRefresh: false
            }
        },
        enterTotp: {
            name: 'passwort_aendern_totp',
            route: '/passwort/passwort_aendern_totp',
            titleKey: 'forgetPassword.twoFactorsAuth.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'forgotten login data new password',
                    step: 2,
                    siteStructure: ['login', 'forgotten login data', 'set password', 'enter-totp'],
                    siteArea: 'meinvodafone',
                    stepName: 'enter-totp',
                    status: [],
                    hasOApplication: false
                },
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.cancel.std-headline',
                navigationLink: '/login'
            },
            fallbackDeeplinking: {
                url: '/login',
                disableRefresh: false
            }
        },
        enterBuc: {
            name: 'passwort_aendern_buc',
            route: '/passwort/passwort_aendern_buc',
            titleKey: 'forgetPassword.twoFactorsAuth.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'forgotten login data new password',
                    step: 2,
                    siteStructure: ['login', 'forgotten login data', 'set password', 'enter-buc'],
                    siteArea: 'meinvodafone',
                    stepName: 'enter-buc',
                    status: [],
                    hasOApplication: false
                },
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.cancel.std-headline',
                navigationLink: '/login'
            },
            fallbackDeeplinking: {
                url: '/login',
                disableRefresh: false
            }
        },

    },
    unauthorized: {
        name: 'nicht_erlaubt',
        route: '/nicht_erlaubt',
        navigation: {
            topLink: true,
            mobileBtn: false,
            navigationLink: '/meinvodafone/services/',
            mobileBtnLabelKey: 'common.buttons.cancel.std-headline',
        }

    },
    generalError: {
        name: 'allgemeiner_fehler',
        route: '/allgemeiner_fehler'
    },
    forgetUsername: {
        name: 'benutzername',
        route: '/benutzername',
        processKey: 'com.uxpsystems.mint.vodafone.process.frontend.usernameForgotten.v1.0',
        navigation: {
            topLink: true,
            mobileBtn: true,
            navigationLink: '/login'
        },
        notifyBeforeUnload: true,
        chooseMethods: {
            name: 'auswahl_methode',
            route: '/benutzername/auswahl_methode',
            stepName: 'choose-method',
            titleKey: 'forgetUsernameModule.chooseMethod.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['login', 'forgotten login data', 'username forgotten', 'choose method'],
                    module: 'username forgotten',
                    step: 1,
                    stepName: 'choose method',
                    status: ['start'],
                    hasOApplication: false
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.back.std-headline',
                navigationLink: '/login/zugangsdaten_vergessen'
            }
        },
        recoverNotPossible: {
            name: 'nicht_moeglich',
            route: '/benutzername/nicht_moeglich',
            titleKey: 'forgetUsernameModule.recoverNotPossible.std-headline',
            stepName: 'recover-not-possible',
            notifyBeforeUnload: false,
            tracking: {
                trackingType: 'page',
                trackingTags: {
                    siteStructure: [
                        'login',
                        'forgotten login data',
                        'choose alternative methode',
                        'choose contract type',
                        'no contract - no us reset possible'],
                    siteArea: 'meinvodafone'
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.back.std-headline'
            }
        },
        alternativeEmail: {
            name: 'alternative_email',
            route: '/benutzername/alternative_email',
            stepName: 'enter-email',
            titleKey: 'forgetUsernameModule.alternativeEmail.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['login', 'forgotten login data', 'username forgotten', 'enter e-mail'],
                    siteArea: 'meinvodafone',
                    module: 'username forgotten',
                    step : 2,
                    stepName: 'enter e-mail',
                    status : [],
                    hasOApplication: false
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.back.std-headline',
                navigationLink: '/benutzername/auswahl_methode'
            }
        },
        chooseContract: {
            name: 'auswahl_vertragstyp',
            route: '/benutzername/auswahl_vertragstyp',
            stepName: 'enter-identifier',
            titleKey: 'forgetUsernameModule.chooseContract.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['login', 'forgotten login data', 'username forgotten', 'choose contract type'],
                    siteArea: 'meinvodafone',
                    module: 'username forgotten',
                    step : 3,
                    stepName: 'choose contract type',
                    status : [],
                    hasOApplication: false
                },
                transactionState: 'start'
            }
        },
        vodafoneContract: {
            name: 'vertrag_vf',
            route: '/benutzername/vertrag_vf',
            stepName: 'enter-identifier',
            titleKey: 'forgetUsernameModule.vodafoneContract.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['login', 'forgotten login data', 'username forgotten', 'choose contract type', 'choose VF contract'],
                    siteArea: 'meinvodafone',
                    module: 'username forgotten',
                    step: 4,
                    stepName: 'choose vf contract type',
                    status : [],
                    hasOApplication: false
                }
            }
        },
        dslContract: {
            name: 'vertrag_dsl',
            route: '/benutzername/vertrag_dsl',
            stepName: 'enter-identifier',
            titleKey: 'forgetUsernameModule.dslContract.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['login', 'forgotten login data', 'username forgotten', 'choose contract type', 'choose VF contract', 'edit DSL contract'],
                    siteArea: 'meinvodafone',
                    module: 'username forgotten',
                    step : 5,
                    stepName: 'edit vf dsl contract' ,
                    status : [],
                    hasOApplication: false
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: '/benutzername/vertrag_vf'
            }
        },
        mobileContract: {
            name: 'vertrag_mobil',
            route: '/benutzername/vertrag_mobil',
            stepName: 'enter-identifier',
            titleKey: 'forgetUsernameModule.mobileContract.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['login', 'forgotten login data', 'username forgotten', 'choose contract type', 'choose VF contract', 'edit mobile contract'],
                    module: 'username forgotten',
                    step : 5,
                    stepName: 'edit vf mobile contract',
                    status : [],
                    hasOApplication: false
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: '/benutzername/vertrag_vf'
            }
        },
        mobileEnterCredentials: {
            name: 'vertragsdaten_mobil',
            route: '/benutzername/vertragsdaten_mobil',
            stepName: 'enter-credentials',
            titleKey: 'forgetUsernameModule.mobileEnterCredentials.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'forgotten login data',
                    step: '4b',
                    siteStructure: ['login data', 'forgotten login data'],
                    stepName: 'username: edit mobile contract',
                    status: []
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: '/benutzername/vertrag_vf'
            }
        },
        cableContract: {
            name: 'vertrag_kabel',
            route: '/benutzername/vertrag_kabel',
            stepName: 'enter-identifier',
            titleKey: 'forgetUsernameModule.cableContract.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['login', 'forgotten login data', 'username forgotten', 'choose contract type', 'edit VKD contract'],
                    module: 'username forgotten',
                    step: 5,
                    stepName: 'edit vkd contract',
                    status: []
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: '/benutzername/auswahl_vertragstyp'
            }
        },
        emailConfirmation: {
            name: 'email',
            route: '/benutzername/email',
            titleKey: 'forgetUsernameModule.emailConfirmation.std-headline',
            confirmationPage: true,
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['login', 'forgotten login data', 'username forgotten', 'confirmation via email'],
                    module: 'username forgotten',
                    step: 6,
                    stepName: 'confirmation via email',
                    outcome: '{{outcome}}',
                    status: ['end', 'success'],
                    hasOApplication: false
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: false,
                mobileBtnLabelKey: 'forgetUsernameModule.emailConfirmation.button.std-headline',
                navigationLink: '/login'

            }

        },
        terminateHotline: {
            name: 'fehler_hotline',
            route: '/benutzername/fehler_hotline',
            titleKey: 'forgetUsernameModule.callHotline.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteArea: 'meinvodafone',
                    siteStructure: ['login', 'forgotten login data', 'username forgotten', 'reached max tries'],
                    module: 'username forgotten',
                    stepName: 'reached max tries',
                    step: 5,
                    status: ['end', 'failure'],
                    outcome: '{{outcome}}'
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'forgetUsernameModule.callHotline.button.std-headline',
                navigationLink: '/login'
            }
        },
        alternativeProcess: {
            name: 'alternative_vertrag',
            route: '/benutzername/alternative_vertrag',
            stepName: 'enter-alternative-process',
            titleKey: 'forgetUsernameModule.alternativeProcess.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'username forgotten',
                    siteStructure: ['login', 'forgotten login data', 'username forgotten', 'alternative options'],
                    siteArea: 'meinvodafone',
                    step: 4,
                    stepName: 'alternative options',
                    status : [],
                    hasOApplication: false
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: '/login'
            }

        },
        alternativeMtan: {
            name: 'alternative_mtan',
            route: '/benutzername/alternative_mtan',
            stepName: 'enter-msisdn',
            titleKey: 'forgetUsernameModule.alternativeMtan.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['login', 'forgotten login data', 'username forgotten', 'enter msisdn'],
                    siteArea: 'meinvodafone',
                    module: 'username forgotten',
                    step : 2,
                    stepName: 'enter msisdn',
                    status : [],
                    hasOApplication: false
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.back.std-headline',
                navigationLink: '/benutzername/auswahl_methode'
            }
        },
        validateMtan: {
            name: 'validateMtan',
            route: '/benutzername/validateMtan',
            stepName: 'validate-mTan',
            titleKey: 'forgetUsernameModule.alternativeMtan.title.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['login', 'forgotten login data', 'username forgotten', 'recovery via mtan'],
                    siteArea: 'meinvodafone',
                    module: 'username forgotten',
                    step : 3,
                    stepName: 'recovery via mtan',
                    status : [],
                    hasOApplication: false
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.back.std-headline',
                navigationLink: '/benutzername/auswahl_methode'
            }
        },
        confirmation: {
            name: 'confirmation',
            route: '/benutzername/confirmation',
            stepName: 'confirmation',
            titleKey: 'forgetUsernameModule.alternativeMtan.title.std-headline',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: ['login', 'forgotten login data', 'username forgotten', 'confirmation via mtan'],
                    siteArea: 'meinvodafone',
                    module: 'username forgotten',
                    step : 6,
                    stepName: 'confirmation via mtan',
                    status : ["end", "success"]
                },
                transactionState: 'start'
            },
            navigation: {
                topLink: true,
                mobileBtn: false,
                mobileBtnLabelKey: 'forgetUsernameModule.emailConfirmation.button.std-headline',
                navigationLink: '/login'
            }
        }

    },
    logout: {
        targetUrl: '/meinvodafone/meinvf_logout.html',
        timeoutUrl: '/meinvodafone/meinvf_timeout.html',
        tracking: {
            trackingTags: {
                module: 'logout',
                siteStructure: ['logout', 'manual logout'],
                stepName: 'login',
                step: 1,
                status: ['start']
            }
        }
    },
    activatePhoneNumber: {
        name: 'rufnummer',
        route: '/rufnummer',
        processName: "com.uxpsystems.mint.vodafone.process.frontend.manageTAN.v1.0",
        titleKey: 'activatePhoneNumber.std-headline',
        access:{
            accessLevel: 'account',
            excludeList : ['donald' , 'della' , 'daisy', 'ralf'],
        },
        navigation: {
            topLink: true,
            mobileBtn: true,
            navigationLink: ''

        },
        enterPhoneNumber : {
            name: 'aendern',
            route: '/rufnummer/aendern',
            stepName: "enter-phone-number",
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: [ 'verwaltung', 'ueberblick', 'update and verify mtan', 'add phone number'],
                    siteArea: 'meinvodafone',
                    module: 'update and verify mtan',
                    step: 1,
                    stepName: 'add phone number',
                    status: ['start']
                }
            },
            access:{
                accessLevel: 'account',
                excludeList : ['donald' , 'della' , 'daisy', 'ralf'],
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: '/verwaltung/ueberblick?accordion=manageAccountData'
            }
        },
        validateMtan : {
            name: 'aktivieren',
            route: '/rufnummer/aktivieren',
            stepName: "validate-mTan",
            access:{
                accessLevel: 'account',
                excludeList : ['donald' , 'della' , 'daisy', 'ralf'],
            },
            tracking: {
                trackingTags: {
                    siteStructure: ['mein vodafone', 'vertragsverwaltung', 'manage userdata', 'update and verify mtan', 'verify mtan'],
                    siteArea: 'meinvodafone',
                    loginStatus : "logged in",
                    oForm: {
                        name : "update and verify mtan",
                        formStep : 2,
                        formStepName : 'verify mtan',
                        formStatus : '[]',
                        type: 'ecare',
                        oApplication: {
                            name : "update and verify mtan",
                            type : "myvodafone:transaction"
                        }
                    }
                }
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: '',
                mobileBtnLabelKey: 'common.buttons.cancel.std-headline'

            },
        },
        confirmation : {
            name: 'aktiviert',
            route: '/rufnummer/aktiviert',
            confirmationPage: true,
            stepName: "confirmation",
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure: [ 'verwaltung', 'ueberblick', 'update and verify mtan', 'confirmation'],
                    siteArea: 'meinvodafone',
                    module: 'update and verify mtan',
                    step: 3,
                    stepName: 'confirmation',
                    status: ['end','success'],
                    outcome: '{{outcomeConfirmation}}',

                }
            },
            access:{
                accessLevel: 'account',
                excludeList : ['donald' , 'della' , 'daisy', 'ralf'],
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                navigationLink: '/verwaltung/ueberblick?accordion=manageAccountData'
            },
        }
    },
    acceptUserInvitation: {
        name: 'einladung',
        route: '/einladung',
        notifyBeforeUnload: true,
        processKey: 'com.uxpsystems.mint.vodafone.process.frontend.acceptUserInvitation.v1.0',
        navigation: {
            topLink: true,
            mobileBtn: true,
            navigationLink: '/meinvodafone/account/login'
        },
        chooser: {
            name: 'annehmen',
            route: '/einladung/annehmen',
            notifyBeforeUnload: false,
            stepName: 'choose-method',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'accept invitation',
                    step: 1,
                    stepName: 'invitation landing page',
                    status: ['start'],
                    siteStructure: ['register user', 'accept invitation']
                }
            },
            navigation: {
                topLink: false,
                mobileBtn: false,
                navigationLink: '/meinvodafone/account/login'
            }
        },
        submitPersonalData: {
            name: 'persoenliche_daten',
            route: '/einladung/persoenliche_daten',
            stepName: 'submit-personal-data',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'accept invitation',
                    step: 2,
                    stepName: 'personal data',
                    status: ['progressing'],
                    siteStructure: ['register user', 'accept invitation'],
                    hasOApplication: false
                }
            }
        },
        submitContactData: {
            name: 'kontaktdaten',
            route: '/einladung/kontaktdaten',
            stepName: 'submit-contact-data',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'accept invitation',
                    step: 3,
                    stepName: 'contact data',
                    status: ['progressing'],
                    siteStructure: ['register user', 'accept invitation']
                }
            }
        },
        submitUserData: {
            name: 'accountdaten',
            route: '/einladung/accountdaten',
            stepName: 'submit-user-data',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'accept invitation',
                    step: 4,
                    stepName: 'user data',
                    status: ['progressing'],
                    siteStructure: ['register user', 'accept invitation']
                }
            }
        },
        reviewData: {
            name: 'uebersicht',
            route: '/einladung/uebersicht',
            stepName: 'review-data',
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'accept invitation',
                    step: 5,
                    stepName: 'review account data',
                    status: ['progressing'],
                    siteStructure: ['register user', 'accept invitation']
                }
            }
        },
        confirmation: {
            name: 'bestaetigung',
            route: '/einladung/bestaetigung',
            confirmationPage: true,
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    module: 'accept invitation',
                    step: 6,
                    stepName: 'confirmation account set up',
                    status: ['end', 'success'],
                    siteStructure: ['register user', 'accept invitation']
                }
            },
            navigation: {
                topLink: false,
                mobileBtn: false
            }
        }
    },
    solsticeFirstLogin: {
            name: 'willkommen',
            route: '/willkommen',
            processKey: 'com.uxpsystems.mint.vodafone.process.frontend.solsticeFirstLogin.v1.0',
            titleKey: 'solsticeFirstLoginModule.title.std-headline',
            access:{
                accessLevel: 'account',
            },
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.back.std-headline',
                headerKey: 'common.buttons.back.std-headline',
                navigationLink: '/login'
            },
            enterPassword: {
                name: 'firstlogin',
                route: '/willkommen/firstlogin',
                stepName: 'enter-password',
                titleKey: 'solsticeFirstLoginModule.title.std-headline',
                navigation: {
                    topLink: true,
                    mobileBtn: true,
                    mobileBtnLabelKey: 'common.buttons.cancel.std-headline',
                    headerKey: 'common.buttons.cancel.std-headline',
                    navigationLink: '/login'
                },
                tracking: {
                    trackingType: 'transaction',
                    trackingTags: {
                        module: 'Solstice first login',
                        step: 1,
                        stepName: 'start',
                        status: ['start'],
                        siteStructure: ['first login', 'start']
                    }
                }
            },
            loginConfirmation: {
                name: 'loginConfirmation',
                route: '/willkommen/loginConfirmation',
                titleKey: 'solsticeFirstLoginModule.title.std-headline',
                confirmationPage: true,
                navigation: {
                    topLink: true,
                    mobileBtn: true,
                    mobileBtnLabelKey: 'common.buttons.back.std-headline',
                    headerKey: 'common.buttons.back.std-headline',
                    navigationLink: '/login'
                },
                tracking: {
                    trackingType: 'transaction',
                    trackingTags: {
                        module: 'Solstice first login',
                        step: 2,
                        stepName: 'confirmation',
                        status: ['end', 'success'],
                        siteStructure: ['first login', 'start']
                    }
                }
            }
    },
    umActivationRequest: {
        name: 'aktivierungscode_anfrage',
        route: '/aktivierungscode_anfrage',
        titleKey: 'requestActivationCode.headline.std-headline',
        daten: {
            name: 'datenum',
            route: '/aktivierungscode_anfrage/datenum',
            titleKey: 'requestActivationCode.headline.std-headline',
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.cancel.std-headline',
                navigationLink: '/meinvodafone/account/login'
            },
            tracking: {
                trackingType: 'transaction',
                trackingTags: {
                    siteStructure:["meinvodafone", "registration" , "request um activation code" ],
                    siteArea: 'meinvodafone',
                    module: 'request activation',
                    step: 1,
                    stepName: 'enter customer data',
                    status: ['start']
                }
            },
        },
        umActivationRequestConfrimation: {
            name: 'confirmation',
            route: '/aktivierungscode_anfrage/confirmation',
            titleKey: 'requestActivationCode.headline.std-headline',
            navigation: {
                topLink: true,
                mobileBtn: true,
                mobileBtnLabelKey: 'common.buttons.cancel.std-headline',
                navigationLink: '/meinvodafone/account/login'
            },
            tracking:{
                trackingType: 'transaction',
                trackingTags: {
                siteStructure:["meinvodafone", "registration" , "request um activation code"  , "confirmation"],
                    status : ["end","success"],
                    name: 'request um activation code',
                    stepName : 'confirmation',
                    type: 'ecare',
                    step : 2,
            }
        },
        confirmationPage: true
        }
    },
};
