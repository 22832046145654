import { PlatformLocation } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { CoreAuthenticationService } from '@vfde-fe/ngx-lib/services/core-authentication';
import { RoutesUtilsService } from '@vfde-fe/ngx-lib/services/routes-utils';
import { WHITE_LIST } from '@vfde-fe/ngx-lib/core';
import { Route } from '@vfde-fe/ngx-lib/models/route-model';
import { filter, find } from 'lodash';
import { Observable, Subject } from 'rxjs';
import * as pagesConfig from '../../../config/pages-config';
import { API_URLS } from '../constants/routes-config';
import { PAGE_TYPE, COMMON_PROCESSES_KEYS } from './../constants/Defines';
import { ConfigLoaderService } from './config-loader.service';
declare let vf;
declare let $;

/** RoutesService */
@Injectable()
export class RoutesService {
    private pagesConfig: any;

    private backButtonPressedFlag: boolean;

     /** targetURL */
     public targetURL: string;

    /** headerChange */
    public headerChange: Subject<void>;

    /**
     * constructor
     * @param router
     * @param authenticationService
     * @param configLoader
     * @param activatedRoute
     * @param location
     * @param routesUtilsService
     */
  public constructor (
        /** router */
        public router: Router,
        private authenticationService: CoreAuthenticationService,
        private configLoader: ConfigLoaderService,
        private activatedRoute: ActivatedRoute,
        private location: PlatformLocation,
        private routesUtilsService: RoutesUtilsService,
    ) {

        this.headerChange = new Subject<any>();
        // Load pages configuration in routes service
        this.pagesConfig = pagesConfig.config;
        // Route/State change detection

        this.router.events
            .subscribe(event => {

                if (event instanceof NavigationStart) {
                    // const route = this.getRouteUsingURL(event.url);

                    if (!this.routesUtilsService.getHistoryUrl().currentURL.includes('#')) {
                        // detect back button using pervious route
                        if (this.routesUtilsService.getHistoryUrl().previousUrl === event.url) {
                            this.setbackButtonPressedFlag(true);
                        }
                        // set application page history
                        const historyUrl = {
                            previousUrl: this.routesUtilsService.getHistoryUrl().currentURL,
                            currentURL: event.url,
                            currentStep: -1,
                        };
                        this.routesUtilsService.setHistoryUrl(historyUrl);
                    }

                    /** call intercept route function for tracking purpose */
                } else if (event instanceof NavigationEnd) {

                    // if user use back button when he on confirmation page and type of page 'oneTimeTransaction' will redirect him to login page
                    const previousRoute = this.getRouteUsingURL(this.routesUtilsService.getHistoryUrl().previousUrl.split('?')[0]);
                    if (previousRoute && previousRoute.confirmationPage == true && this.getModuleUsingURL(previousRoute.route).pageType === PAGE_TYPE.ONE_TIME_TRANSACTION && this.backButtonPressedFlag) {
                        // go to login
                        this.router.navigate([pagesConfig.config.login.route]);
                    }
                    this.backButtonPressedFlag = false;

                    const route = this.getRouteUsingURL(event.url.split('?')[0]);
                    if (route && route.processKey && (previousRoute && !previousRoute.confirmationPage)) {
                        // if currentURL and previousUrl belong to same process go back on step from history
                        if (this.getModuleUsingURL(this.routesUtilsService.getHistoryUrl().currentURL.split('?')[0]) === this.getModuleUsingURL(this.routesUtilsService.getHistoryUrl().previousUrl.split('?')[0])) {
                            // we should go step back'
                            // console.log("Go step back");
                            this.backButtonPressedFlag = true;
                            history.back();
                        }
                    }
                    setTimeout(function () {
                        if (0 < $('.mod-wm').length) {
                            if (vf['social-sharing']) {
                                $('.mod-wm').off('click', '.toggleSocialList', vf['social-sharing'].toggleSocialList);
                                vf['profile-chooser'].init('.mod-wm');
                                vf['social-sharing'].init('.mod-wm');
                                vf.wm.init('.mod-wm');
                            }
                        }
                    }, 100);
                } else if (event instanceof NavigationCancel) {
                    if (event.url === pagesConfig.config.login.route) {
                        this.router.navigate([pagesConfig.config.login.route]);
                    }
                } else if (event instanceof NavigationError) {
                    // console.log("Navigation Error  " + event);
                }
            });

    }

    /**
     * Takes the path of the title to be setted and changes the titleKey's default value
     * @param titlePath - path of the the object to be setted
     * @param titleKey - new titleKey to be setted
     */
    public setDefaultTitle (titlePath: any, titleKey: string) {
        titlePath.titleKey.DEFAULT = titleKey;
        this.headerChange.next();
    }

    /** getDefaultTitleObs */
    public getDefaultTitleObs (): Observable<any> {
        return this.headerChange.asObservable();
    }

    /** return the pages config object loaded */
    public getPagesConfig () {
        return this.pagesConfig;
    }

    /**
     * get pages configuration of the module name that given in arguments
     * @param moduleName: string - module name
     */
    public getPagesConfigByModule (moduleName: string) {
        return this.pagesConfig[moduleName];
    }

    /**
     * search for module by route's url and return the full route object
     * @param url - route url
     */
    public getModuleUsingURL (url: string): any {
        let module: any = null;
        const conf = this.pagesConfig;
        Object.keys(conf).forEach(moduleKey => {
            if (conf[moduleKey].route === url) {
                module = conf[moduleKey];
            } else {
                Object.keys(conf[moduleKey]).forEach(stepKey => {
                    if (conf[moduleKey][stepKey].route === url) {
                        module = conf[moduleKey];
                    }
                });
            }
        });

        return module;
    }

    /**
     * search for module by route's url and return the full route object
     * @param url - route url
     */
    public getModuleKeyUsingURL (url: string): string {
        let key: string = null;
        const conf = this.pagesConfig;
        Object.keys(conf).forEach(moduleKey => {
            if (conf[moduleKey].route === url) {
                key = moduleKey;
            } else {
                Object.keys(conf[moduleKey]).forEach(stepKey => {
                    if (conf[moduleKey][stepKey].route === url) {
                        key = moduleKey;
                    }
                });
            }
        });

        return key;
    }

    /**
     * search for route by route's url and return the full route object
     * @param url - route url
     * @param moduleName - determine which module will be searched in
     */
    public getRouteUsingURL (url: string): Route {
        let route: Route = null;
        const conf = this.pagesConfig;
        Object.keys(conf).forEach(moduleKey => {
            if (conf[moduleKey].route === url) {
                route = conf[moduleKey];
            } else {
                Object.keys(conf[moduleKey]).forEach(stepKey => {
                    if (conf[moduleKey][stepKey].route === url) {
                        route = conf[moduleKey][stepKey];
                    }
                });
            }
        });

        return route;
    }

    /**
     * returns true if the targetUrl is an internal link and false otherwise
     * @param targetURL - contains the Target url
     * @return {boolean}
     */
    public checkInternalLinks (targetURL: string): boolean {
        return this.getRouteUsingURL(targetURL) !== null;
    }
    /**
     * returns true if the targetUrl is an external link from whitelist and false if it wasn't found in whitelist
     * @param targetURL - contains the Target url
     * @return {boolean}
     */
    public checkExternalLinks (targetURL: string): boolean {
        const whitelist: string[] = WHITE_LIST;
        const result = filter(whitelist, (o: string) => {
 return targetURL.match('^' + o.replace('*', '(.*)'));
});

        return result.length > 0;
    }

    /**
     * for replacing placeholders in string with their actual value
     * example:
     * INPUT:
     * str = "/apis/%APINAME%/FEATURE/%ITEM_ID%/CUSTOMER"
     * data = {"%APINAME%":"CUSTOMER_SNAPSHOT","ITEM_ID%":"5"}
     * OUTPUT:
     * output = /apis/CUSTOMER_SNAPSHOT/FEATURE/5/CUSTOMER
     * @param str
     * @param data
     */
    public replacePlaceHolders (str, data) {
        const output = str.replace(/%[^%]+%/g, function (match) {
            if (match in data) {
                return (data[match]);
            } else {
                return ('');
            }
        });

        return (output);
    }

    /**
     * Build the url with query parameter provided
     * @param base - base url
     * @param key - query parameter key
     * @param value - query parameter value
     */
    public buildUrlWithQueryParam (base: string, key: string, value: string): string {
        const sep = (base.indexOf('?') > -1) ? '&' : '?';
        value = encodeURI(value).replace(/#/g, '%23');

        return base + sep + key + '=' + value;
    }

    /** getModuleRouteUsingProcessKey */
    public getModuleRouteUsingProcessKey (requestUrl: string) {
        let moduleRoute = '';
        find(COMMON_PROCESSES_KEYS, (processDetails: {
            /** process key */
            processKey: string,
            /** process route */
            route: string,
        }) => {


            if (requestUrl.indexOf(processDetails.processKey) != -1) {

                moduleRoute = processDetails.route;

                return true;
            }

            return false;
        });

        return moduleRoute;
    }
    /**
     * getter for backButtonPressedFlag
     */
    public getbackButtonPressedFlag (): boolean {
        return this.backButtonPressedFlag;
    }
    /**
     * setter for backButtonPressedFlag
     * @param flag
     */
    public setbackButtonPressedFlag (flag: boolean) {
        this.backButtonPressedFlag = flag;
    }

    /**
     * it accepts a url then check if it was internal or external one
     * if the url wasn't internal nor external included in the whitelist, it will navigate to the default dashboard
     * @param url - the url to be navigated to
     */
    public navigateToURL (url: string, replaceUrl?: boolean) {

        url = decodeURIComponent(url);

        const redirectTo = url.split('#')[0].split('?')[0];

        if (this.checkInternalLinks(redirectTo)) {
            if (replaceUrl) {
                this.router.navigateByUrl(url, { replaceUrl: true });
            } else {
                this.router.navigateByUrl(url);
            }
        } else if (this.checkExternalLinks(this.applyDomainToUrl(url))) {

            window.location.href = url;
        } else {
            window.location.href = window.location.origin + API_URLS.ECARE_ROUTES.DASHBOARD;
        }
    }

    /**
     * checks whether the url has a domain or not, if the url doesn't have a domain then it appends the application's
     * default to main to the url and returns it back
     * @param url
     */
    public applyDomainToUrl (url: string): string {
        if (url.startsWith('/')) {
            return window.location.origin + url;
        } else {
            return url;
        }
    }

    /** to get page title from pages config file based on page route */
    public getRouteTitle (lastRoute) {

        let pageTitleKey = '';

        /** get configuration object depends on current url */
        const route = this.getRouteUsingURL(lastRoute.split('#')[0].split('?')[0]);

        if (route && typeof route.titleKey === typeof {}) {

            /** dynamic title key */
            pageTitleKey = route.titleKey[route.titleKey.DEFAULT];
        } else if (route) {

            /** static title key */
            pageTitleKey = route.titleKey || 'common.std-headline';
        }

        return pageTitleKey;
    }
    /**
     * function that returns the history URL object
     */
    public getHistoryUrl () {
        return this.routesUtilsService.getHistoryUrl();
    }
    /**
     * handling if target url exists, it will navigate to it
     * otherwise, it will navigate to the ecare dashboard
     * @param targetUrl - target url that will navigate to
     */
    public handlingTargetUrl (targetUrl: string) {
        if (targetUrl) {
            this.navigateToURL(targetUrl);
        } else {
            window.location.href = window.location.origin + API_URLS.ECARE_ROUTES.DASHBOARD;
        }
    }
}
