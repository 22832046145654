import { Injectable } from '@angular/core';
import { Process } from '../../models/process.model';
import { Observable , throwError} from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RoutesService } from '../utils/routes.service';
import {find} from 'lodash';
import { environment } from '../../../environments/environment';
import { API_URLS, PROCESS_ROUTES } from '../constants/routes-config';
import { SIMPLICITY_ENV } from '../constants/Defines';
import { Route } from '@vfde-fe/ngx-lib/models/route-model';
import { Helper } from '@vfde-fe/ngx-lib/services/helper-utils';
import { map, catchError } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

/** ProcessService */
@Injectable()
export class ProcessService {

  /** _currentModule */
  public _currentModule = '';
  /** _currentUsecase */
  public _currentUsecase = '';
  /** _currentStep */
  public _currentStep = '';
  /** _processId */
  public _processId = '';
  /** conf */
  public conf: any;
  /** navigate */
  public navigate: boolean;
  /** isFromFourm */
  public isFromFourm: boolean;
  /** captcha reload */
  public captchaReload = new BehaviorSubject<boolean>(false);
public constructor (private routesService: RoutesService, private httpClient: HttpClient, private helperService: Helper ) {
    this.conf = this.routesService.getPagesConfig();
  }

  /** Process Start */
  public startProcess (processStartKey: string , navigate = true): Observable<any> {
    this.navigate = navigate;
    const headers: any = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json' });
    const options = { headers: headers };

    const url = API_URLS.PROCESS.START + processStartKey;

    return this.httpClient.post(url,
      {}, options).pipe(map((response: any) => {
        const data = response;
        this._processId = data.processId;

        return response;
      }));
  }

  /*Process Step */
  /** submitProcessStep */
  public submitProcessStep (requestBody, errorCallBack, headersObj?: HttpHeaders, that? , navigate = true, umAccountNumber?: string, isFromFourm?: boolean, option?) {
    this.navigate = navigate;
    if ( isFromFourm) {
        this.isFromFourm = isFromFourm;
    }
    const actionType = 'com.uxpsystems.mint.framework.bpm.process.ManualInputStepAction';

    const requestPayload: Process = new Process(actionType, this._processId, this.getCurrentStep());
    requestPayload.parameters = requestBody || {};

    if (umAccountNumber) {
        requestPayload.umAccountNumber = umAccountNumber;
    }

    const headers: any = headersObj || new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json' });
    const options = option || { headers: headers };

    const url = this.getProcessStepURL();

    return this.httpClient.put(url,
      requestPayload, options)
      .pipe(map((response: any) => {
        const data = response;
        this._processId = data.processId;

        return data;
      }))
      .pipe(catchError((error) => {
        this.captchaReload.next(true);

        return throwError(errorCallBack(error, that) || error);
      }));
  }

  /** handleGenericRouting */
  public handleGenericRouting (stepName: string): string {
    this._currentStep = stepName;
    const step = this.getStepUsingStepName(stepName);

    // return this._processRoutesMap[this._currentModule][stepName].route;
    return step ? step.route : null;
  }

  /** checkIfProcessUri */
  public checkIfProcessUri (url: string): boolean {
    if ((environment.stubMode || this.helperService.getCookieData('environment') == SIMPLICITY_ENV.dev) && (url.indexOf(API_URLS.PROCESS.START) == -1 || url.indexOf(API_URLS.PROCESS.NEW_START) == -1)) {
      url = url.replace(/\/process\/(.+)/, '/process/step');
    }

    return !!PROCESS_ROUTES.filter(item => url.indexOf(item) > -1).length;

    // let isProcessUri = PROCESS_ROUTES.indexOf(url) > -1;
    // return isProcessUri;
  }

  /** setCurrentModule */
  public setCurrentModule (currentModule: string): void {
    this._currentModule = currentModule;
  }

  /** getCurrentModule */
  public getCurrentModule (): string {
    return this._currentModule;
  }
  /** setCurrentUsecase */
  public setCurrentUsecase (currentUsecase: string): void {
    this._currentUsecase = currentUsecase;
  }

  /** getCurrentUsecase */
  public getCurrentUsecase  (): string {
    return this._currentUsecase;
  }
  /** setCurrentStep */
  public setCurrentStep (currentStep: string): void {
    this._currentStep = currentStep;
  }

  /** getCurrentStep */
  public getCurrentStep (): string {
    return this._currentStep;
  }

  /** setProcessId */
  public setProcessId (processId: string): void {
    this._processId = processId;
  }

  /** getProcessId */
  public getProcessId (): string {
    return this._processId;
  }

  /** getStepUsingName */
  public getStepUsingName (name: string, moduleName?: string): Route {
    return this.conf[moduleName || this._currentModule][name];
  }
  /** getStepUsingStepName */
  public getStepUsingStepName (stepName: string, moduleName?: string): Route {
    const step = find(this.conf[moduleName || this._currentModule], function (item: any) {
      return item.stepName === stepName;
    });

    return step;
  }
  /**
   * search for step by step's url and return the full step object
   * @param stepURL - search by step url
   * @param moduleName - determine which module will be searched in
   */
  public getStepUsingStepURL (stepURL: string, moduleName?: string): Route {
    const step = find(this.conf[moduleName || this._currentModule], (item: Route) => item.route === stepURL);

    return step;
  }
  /** getProcessStepURL */
  public getProcessStepURL (): string {
    if (environment.stubMode || this.helperService.getCookieData('environment') == SIMPLICITY_ENV.dev) {
        const url = API_URLS.PROCESS.STEP.replace(/step/i, this._currentStep);

      return url;
    } else {

      return API_URLS.PROCESS.STEP;
    }
  }

}
