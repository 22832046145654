import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CoreConfigurationsLoader } from '@vfde-fe/ngx-lib/services/core-configurations-loader';
import { regex } from '@vfde-fe/ngx-lib/core';

import { of } from 'rxjs';
import { config } from '../../../config/pages-config';
import { environment } from '../../../environments/environment';

/** ConfigLoaderService */
@Injectable()
export class ConfigLoaderService extends CoreConfigurationsLoader {

    /** errors */
    public errors: any;
    /** features */
    public features: any;
    /** defaultGermanWordingfile */
    public defaultGermanWordingfile: any;

    /** deWordingfile */
    public deWordingfile: any;
    /** commonErrors */
    public commonErrors: any;
    /** commonWording */
    public commonWording: any;
    /** coreDefaultGermanWording */
    public coreDefaultGermanWording: any;
    /** context */
    public context: string;

    /**
     * constructor
     * @param http
     */
    public constructor (private http: HttpClient) {
        super();
        const currentUrl = window.location.href;
        const availableContext = currentUrl.match(regex.context);
        this.context = availableContext ? availableContext[1] : '';
    }

    /**
     * loads all configuration files at once by trying getting it from cms first then it gets the local one if it failed
     * the configutation files includes the following:
     * error-message - the error messages file
     * common.error - the common error messages for the general error
     * defaultWording - the local default wording file
     * deWording - the german wording file on CMS
     *             it first checks if context available and tries getting it,
     *             if it failed to load the context-dewording file for any reason it tries getting the general deWording
     */
    public load (): Promise<any> {

        const errorsConfig = new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(environment.wordingPath + 'error-messages.json').subscribe(response => {
                    try {

                        this.errors = response;

                        resolve(this.errors);
                    } catch (error) {

                        console.warn('not valid json for error-messages.json file');

                        this.http.get(environment.configPath + 'error-messages.json').subscribe(response => {
                            this.errors = response;
                            resolve(this.errors);
                        });
                    }
                }, error => {
                    this.http.get(environment.configPath + 'error-messages.json').subscribe(response => {
                        this.errors = response;
                        resolve(this.errors);
                    });
                });
            });
        });

        const commonErrorsConfig = new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(environment.cmsErrorMessagesPath + 'common.error.js').subscribe(response => {

                    try {

                        this.commonErrors = response;

                        resolve(this.commonErrors);
                    } catch (error) {

                        console.warn('not valid json for common.error.js file');

                        this.http.get(environment.configPath + 'common.error.json').subscribe(response => {
                            this.commonErrors = response;
                            resolve(this.commonErrors);
                        });
                    }
                }, error => {
                    this.http.get(environment.configPath + 'common.error.json').subscribe(response => {
                        this.commonErrors = response;
                        resolve(this.commonErrors);
                    });
                });
            });
        });

        const deWording = new Promise((resolve, reject) => {
            setTimeout(() => {
                if (this.context) {
                    this.http.get(environment.oneloginCMSPath + 'i18n/deWording-' + this.context + '.json').subscribe(response => {

                        try {

                            this.deWordingfile = response;
                            resolve(this.deWordingfile);
                        } catch (error) {
                            console.warn('not valid json for deWording' + this.context + '.json file');
                            this.deWordingfile = {};
                            resolve(null);
                        }
                    }, (err) => {
                        this.http.get(environment.wordingPath + 'i18n/deWording.json').subscribe(response => {

                            try {
                                this.deWordingfile = response;
                                resolve(this.deWordingfile);
                            } catch (error) {
                                console.warn('not valid json for deWording.json file');
                                this.deWordingfile = {};
                                resolve(null);
                            }
                        }, (err) => {
                            resolve(null);
                        });
                    });
                } else {
                    this.http.get(environment.wordingPath + 'i18n/deWording.json').subscribe(response => {

                        try {
                            this.deWordingfile = response;
                            resolve(this.deWordingfile);
                        } catch (error) {
                            console.warn('not valid json for deWording.json file');
                            this.http.get(environment.configPath + 'i18n/defaultWording.json').subscribe(response => {
                                this.deWordingfile = response;
                                resolve(this.deWordingfile);
                            });
                        }
                    }, (err) => {
                        this.http.get(environment.configPath + 'i18n/defaultWording.json').subscribe(response => {
                            this.deWordingfile = response;
                            resolve(this.deWordingfile);
                        });
});
                }
            });
        });

        const getGermanWording = new Promise((resolve, reject) => {

            deWording.then((value) => {
                if (!value || this.context) {

                    setTimeout(() => {
                        this.http.get(environment.configPath + 'i18n/defaultWording.json').subscribe(response => {
                            this.defaultGermanWordingfile = response;
                            resolve(this.defaultGermanWordingfile);
                        });
                    });
                } else {

                    resolve(value);
                }
            });
        });

        const coreWording = new Promise((resolve, reject) => {

            setTimeout(() => {
                this.http.get(environment.cmsCommonWording).subscribe(response => {
                    try {

                        this.commonWording = response;

                        resolve(this.commonWording);
                    } catch (error) {

                        console.warn('not valid json for core-deWording.json file');

                        resolve(null);
                    }
                }, (err) => {
                    resolve(null);
                });
            });
        });

        const getGermanWordingCore = new Promise((resolve, reject) => {

            coreWording.then((value) => {

                if (!value) {

                    setTimeout(() => {
                        this.http.get(environment.configPath + 'i18n/defaultWording-core.json').subscribe(response => {
                            this.coreDefaultGermanWording = response;
                            resolve(this.coreDefaultGermanWording);
                        }, (error) => {
                            resolve(null);
                        });
                    });
                } else {

                    resolve(value);
                }
            });
        });

        return Promise.all([errorsConfig, commonErrorsConfig,
            getGermanWording, getGermanWordingCore]);
    }

    /** loadPagesRoutes */
    public loadPagesRoutes (): object | object[] {
        return config;
    }

    /** getcommonErrorsConfig */
    public getcommonErrorsConfig () {
        return this.commonErrors;
    }

    /** getErrorsConfig */
    public getErrorsConfig () {
        return this.errors;
    }

    /** getTokenConfig */
    public getTokenConfig () {
      return '';
    }
    /** getDefaultGermanWordingFile */
    public getDefaultGermanWordingFile () {
        return of(Object.assign(this.defaultGermanWordingfile || {}, this.coreDefaultGermanWording || {}));
    }
    /** getDeWordingFile */
    public getDeWordingFile () {

        return of(Object.assign(this.deWordingfile || {}, this.commonWording || {}));
    }
    /** setCoreConfigRoute */
    public setCoreConfigRoute (): void {
        this.generalError = this.loadPagesRoutes()['generalError'].route;
        this.unauthorized = this.loadPagesRoutes()['unauthorized'].route;

    }
}
