import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import * as pagesConfig from '../config/pages-config';
import { DisableDeepLinksGuard } from './common/authentication/disable-deeplinks.guard';
import { GeneralErrorComponent } from './common/shared-components/general-error/general-error.component';
import { UnauthorizedComponent } from './common/shared-components/unauthorized/unauthorized.component';

const routes: Routes = [
  {
        //  Landing Page
        path: pagesConfig.config.landingPageModule.name,
        loadChildren: () => import('./document-landing-page/landing-page.module').then(m => m.LandingPageModule),

  },

  {
    // Registration
    path: pagesConfig.config.registration.name,
    loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule),
  },
  {
    // Login
    path: pagesConfig.config.login.name,
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
  },
  //  SEPERATE COMPONENTS
//   {
//     // Home
//     path: pagesConfig.config.home.name,
//     loadChildren: './home/home.module#HomeModule'
//   },
  {
    // Unauthorized
    path: pagesConfig.config.unauthorized.name,
    component: UnauthorizedComponent,
    pathMatch: 'full',
  },
  {
    // accept user invitation
    path: pagesConfig.config.acceptUserInvitation.name,
    loadChildren: () => import('./accept-user-invitation/accept-user-invitation.module').then(m => m.AcceptUserInvitationModule),

  },
  {
    // general error handleing page
    path: pagesConfig.config.generalError.name,
    component: GeneralErrorComponent,
    pathMatch: 'full',
    canActivate: [DisableDeepLinksGuard],
  },
  {
    // forget password
    path: pagesConfig.config.forgetPassword.name,
    loadChildren: () => import('./forget-password/forget-password.module').then(m => m.ForgetPasswordModule),

  },
  {
    // forget username
    path: pagesConfig.config.forgetUsername.name,
    loadChildren: () => import('./forget-username/forget-username.module').then(m => m.ForgetUsernameModule),

  },
  {
    // solstice first login
    path: pagesConfig.config.solsticeFirstLogin.name,
    loadChildren: () => import('./solstice-first-login/solstice-first-login.module').then(m => m.SolsticeFirstLoginModule),
  },
  {
    // RequestUMActivationCode
    path: pagesConfig.config.umActivationRequest.name,
    loadChildren: () => import('./request-um-activation-code/request-um-activation-code.module').then(m => m.RequestUMActivationCodeModuleModule),
  },
];

/** AppRoutingModule */
@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: false })],
    exports: [RouterModule],
})

export class AppRoutingModule { }
