import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { WORDING_FILES } from '../constants/Defines';
import { ConfigLoaderService } from './config-loader.service';

/** CustomTranslateLoader */
export class CustomTranslateLoader extends TranslateHttpLoader {
    private customHttp;
    private config;

    /**
     * constructor
     * @param http
     * @param configLoaderService
     * @param prefix
     * @param suffix
     */
  public constructor (http: HttpClient, configLoaderService: ConfigLoaderService, prefix?: string, suffix?: string) {
        super(http, prefix, suffix);
        this.customHttp = http;
        this.config = configLoaderService;
    }

    /**
     * Get Translation based on lang
     */
    public getTranslation (lang?: string) {
        if (lang === WORDING_FILES.defaultWording) {
            return this.config.getDefaultGermanWordingFile();
        } else if (lang === WORDING_FILES.deWording) {
            return this.config.getDeWordingFile();
        }
    }

}
