import { Component } from '@angular/core';
import { Navigation } from '@vfde-fe/ngx-lib/models/navigation';
import { RoutesUtilsService } from '@vfde-fe/ngx-lib/services/routes-utils';
import { CustomErrorHandlerService } from '@vfde-fe/ngx-lib/services/custom-error-handler';

/** GeneralErrorComponent */
@Component({
    selector: 'app-general-error',
    templateUrl: './general-error.component.html',
    styleUrls: ['./general-error.component.css'],
})
export class GeneralErrorComponent {
    /** error */
    public error;
    /** title */
    public title = '';
    /** accessLevel */
    public accessLevel = null;
    /** navigationDetails */
    public navigationDetails: Navigation;

    /**
     * constructor
     * @param customErrorHandlerService
     * @param routesUtilsService
     */
  public constructor (private customErrorHandlerService: CustomErrorHandlerService, private routesUtilsService: RoutesUtilsService) {
        const data = this.customErrorHandlerService.getCurrentError();

        // get navigation object from current page or current module
        if (this.customErrorHandlerService.currentRoute) {
            const currentPageConfig = this.routesUtilsService.getRouteUsingURL(this.customErrorHandlerService.currentRoute);
            let navigation: Navigation = null;
            if (currentPageConfig.navigation) {
                navigation = currentPageConfig.navigation;
            } else {
                const currentModuleConfig = this.routesUtilsService.getModuleUsingURL(this.customErrorHandlerService.currentRoute);
                navigation = currentModuleConfig.navigation;
            }
            this.navigationDetails = navigation;
        }

        this.title = data.title;
        this.accessLevel = data.accessLevel;
        this.error = data.error;
    }
}
