import { PageScrollService } from 'ngx-page-scroll-core';
import { DisableDeepLinksGuard } from './common/authentication/disable-deeplinks.guard';
import { ConfigLoaderService } from './common/utils/config-loader.service';
import { ConfigWhitelistService } from './common/utils/config-whitelist.service';
import { IdleService } from './common/utils/idle.service';
import { ProcessService } from './common/utils/process.service';
/* this file for separating the service Providers which will be used through the entire application */
import { ValidationService } from './common/utils/validation.service';
import { InfoIconService } from '@vfde-fe/ngx-lib/components/info-icon';
import { AccessGuard } from '@vfde-fe/ngx-lib/guards/access-guard';
import { AccordionInterceptor } from '@vfde-fe/ngx-lib/components/accordion';
import { AccordionService } from '@vfde-fe/ngx-lib/components/accordion';
import { AuthenticationGuard } from '@vfde-fe/ngx-lib/guards/authentication';
import { ConfirmDataLossService } from '@vfde-fe/ngx-lib/services/confirm-data-loss';
import { CustomErrorHandlerService } from '@vfde-fe/ngx-lib/services/custom-error-handler';
import { RoutesService } from './common/utils/routes.service';
import { HelperUtilsService } from './common/utils/helper-utils.service';
import { SubmitPersonalDataDeactivateGuard } from './registration/submit-personal-data/submit-personal-data-deactivate.guard';
import { SpinnerService } from '@vfde-fe/ngx-lib/components/spinner';
import { UserService } from '@vfde-fe/ngx-lib/services/user';
import { DetectDataLossService } from '@vfde-fe/ngx-lib/services/detect-data-loss';
import { StepperService } from '@vfde-fe/ngx-lib/components/stepper-module';

/** APP_PROVIDERS */
export const APP_PROVIDERS = [
    RoutesService,
    ProcessService,
    ValidationService,
    AuthenticationGuard,
    AccessGuard,
    DisableDeepLinksGuard,
    ConfirmDataLossService,
    CustomErrorHandlerService,
    ConfigLoaderService,
    ConfigWhitelistService,
    IdleService,
    AccordionService,
    PageScrollService,
    HelperUtilsService,
    AccordionInterceptor,
    InfoIconService,
    SubmitPersonalDataDeactivateGuard,
    SpinnerService,
    UserService,
    DetectDataLossService,
    StepperService,
];
