import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from '@angular/router';
import { SubmitPersonalDataComponent } from './submit-personal-data.component';
import { ProcessService } from 'src/app/common/utils/process.service';
import { FORUM_URL } from 'src/app/common/constants/Defines';
import * as pagesConfig from '../../../config/pages-config';

/** ConfirmDeactivateGuard */
@Injectable()
export class SubmitPersonalDataDeactivateGuard implements CanDeactivate<SubmitPersonalDataComponent> {
    /** component */
    public component: Object;
    /** route */
    public route: ActivatedRouteSnapshot;
    /** forum */
    public forum;
    /** pagesConfig */
    public pagesConfig = pagesConfig.config;
    /** constructor */
    public constructor (    private processService: ProcessService) {
        this.forum = this.processService.isFromFourm;
    }

    /**
     * canDeactivate
     * @param route
     * @param state
     */
    public canDeactivate (component: SubmitPersonalDataComponent, route: ActivatedRouteSnapshot, state: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        if (this.forum && (nextState.url != this.pagesConfig.registration.submitContactData.route)) {
            window.location.href = FORUM_URL.PATH;

            return false;
        } else {
            return true;
        }
    }
}
