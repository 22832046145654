import { Injectable } from '@angular/core';
import { Helper } from '@vfde-fe/ngx-lib/services/helper-utils';
import { ProcessService } from './process.service';

/** ValidationService */
@Injectable()
export class ValidationService {

    /** attributesMap */
    public attributesMap: any;
    // private currentStep:string;

    /**
     * constructor
     * @param helper
     * @param processService
     */
  public constructor (private helper: Helper, private processService: ProcessService) {
        this.attributesMap = [];
    }

    // create interface attribute

    /** getAttribute */
    public getAttribute (attrName: string, isBEValidation: boolean) {

        if (isBEValidation) {
            const currentStep = this.processService.getCurrentStep();
            const propertyFullName =  attrName + 'Regex';
            if (this.attributesMap[currentStep] && this.attributesMap[currentStep][propertyFullName]) {
                return this.attributesMap[currentStep][propertyFullName].returnData || '';
            } else {
                return '';
            }
        } else {
            return this.helper.getRegexByKey(attrName) || '';
        }
    }

    /** addStepAttributes */
    public addStepAttributes (bodyObject: any) {
        // TODO add attr to the attributesMap
        // this.setCurrentStep(stepName);
        const parametersObject = bodyObject.returnParameters;
        if (typeof (parametersObject) == 'object') {
            this.attributesMap[bodyObject.stepName] = parametersObject;
        } else {
            // TODO handle error
        }

    }

}
