import { environment } from '../../../environments/environment';
import { PROCESS_MODULE_KEY } from './Defines';
/** API_URLS */
export const API_URLS = {
    REGISTRATION: {
        PROCESS_START_KEY: PROCESS_MODULE_KEY.REGISTRATION,
    },
    ECARE_ROUTES: {
        DASHBOARD: '/meinvodafone/services/',
        CONTRACT_CHOOSER: '/meinvodafone/services/vertragsauswahl',
        CONTRACT_OVERVIEW_CUSTOMER_DATA_ACCORDION: '/meinvodafone/services/vertragsdaten?accordion=kundendaten-herunterladen',
    },
    USER: {
        USER_MINT: environment.baseUrlMint + '/rest/user',
    },
    LOGIN: {
        SESSION_START: environment.baseUrlMint + '/rest/v60/session/start',
    },
    SEAMLESS_LOGIN: {
        SESSION_START: environment.baseUrlMint + '/rest/session/start',
        DETECT_SIM : environment.baseUrlMint + '/transparent.gif',
    },
    FORGET_PASSWORD: {
        PROCESS_START_KEY: PROCESS_MODULE_KEY.FORGET_PASSWORD,
    },
    RESET_PASSWORD_TOKEN: {
        PROCESS_START_KEY: PROCESS_MODULE_KEY.RESET_PASSWORD_TOKEN,
    },
    ACCEPT_USER_INVITATION: {
        PROCESS_START_KEY: PROCESS_MODULE_KEY.ACCEPT_USER_INVITATION,
    },
    FORGET_USERNAME: {
        PROCESS_START_KEY: PROCESS_MODULE_KEY.FORGET_USERNAME,
    },
    LOGOUT: {
        LOGOUT: environment.baseUrlMint + '/saml/logout',
    },
    validateMsisdnViaSms: {
        PROCESS_START_KEY: PROCESS_MODULE_KEY.ValidateMsisdnViaSms,
    },
    PROCESS: {
        START: environment.baseUrlMint + '/rest/process/start/',
        STEP: environment.baseUrlMint + '/rest/process/step',
        VLUXGATE_START: environment.baseUrlVluxgate + '/vlux/start/module/',
        VLUXGATE_STEP: environment.baseUrlVluxgate + '/vlux/step/module',
        RESEND_MTAN: environment.baseUrlVluxgate + '/vlux/send-tan',
        NEW_START: environment.baseUrlMint + '/rest/v60/session/start',
    },
    SUBSCRIPTION: {
        UPDATE: environment.baseUrlMint + '/rest/user/{userId}/subscription/{subscriptionId}/association',
        DELETE: environment.baseUrlMint + '/rest/user/{userId}/subscription/{subscriptionId}/association',
    },
    ACTIVATE_PHONE_NUMBER: {
        PROCESS_START_KEY: PROCESS_MODULE_KEY.ACTIVATE_PHONE_NUMBER,
    },
    SOLISTICE_FIRST_LOGIN: {
        PROCESS_START_KEY: PROCESS_MODULE_KEY.SOLSTICE_FIRST_LOGIN,
    },

};

/** PROCESS_ROUTES */
export const PROCESS_ROUTES: Array<string> = [
    API_URLS.PROCESS.STEP,
    API_URLS.PROCESS.START,
    API_URLS.PROCESS.VLUXGATE_START,
    API_URLS.PROCESS.VLUXGATE_STEP,
    API_URLS.PROCESS.NEW_START,

];
