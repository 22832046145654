import { Injectable } from '@angular/core';
import * as pagesConfig from '../../../config/pages-config';
import { SECURITY_QUESTION_TYPES, TRIM_TYPES } from '../constants/Defines';
import { ConfigLoaderService } from './config-loader.service';
import { regex } from '@vfde-fe/ngx-lib/core';
declare let jsonpath: any;

/** HelperUtilsService */
@Injectable()
export class HelperUtilsService {

  public constructor (private configLoaderService: ConfigLoaderService) { }

    /** to get page access level from pages config file based on page route */
    public detectAccessLevel (url: string) {
        const pagesConfigPool = pagesConfig.config;
        const pathNodes = jsonpath.nodes(pagesConfigPool, `$..[?(@.route=="${url}")]`);
        let accessLevel = null;
        if (pathNodes && pathNodes.length && pathNodes[0].path && pathNodes[0].path.length) {
            const paths = pathNodes[0].path;
            paths.splice(0, 1);
            let lastCheckedValue = pagesConfigPool[paths[0]];
            for (let depthIndex = 1; depthIndex < paths.length + 1; depthIndex++) {
                if (lastCheckedValue.access && lastCheckedValue.access.accessLevel) {
                    accessLevel = lastCheckedValue.access.accessLevel;
                }
                lastCheckedValue = lastCheckedValue[paths[depthIndex]];
            }
        }

        return accessLevel;
    }

    /**
     * The function clone new copy for an objects
     */
    public clone (obj) {
        if (null == obj || 'object' != typeof obj) {
 return obj;
}
        const copy = obj.constructor();
        for (const attr in obj) {
            if (obj.hasOwnProperty(attr)) {
 copy[attr] = obj[attr];
}
        }

        return copy;
    }

    /** accepts to array, check and return the difference between them
     * @param oldArray - the old Array saved in the service
     * @param newArray - the new Array recently updated by the user
     */
    public difference (oldArray: any[], newArray: any[]) {
        let diff = [];
        diff = oldArray.filter((oldItem) => {
            const same = newArray.find((newItem) => {
                return newItem.value == oldItem.value;
            });
            if (!same) {
                delete oldItem.answer;
                oldItem.flag = SECURITY_QUESTION_TYPES.delete;

                return true;
            } else {
 return false;
}
        });

        return diff;
    }

    /**
     * normalize the mobileIdentifier (MSISDN) according
     * to the MSISDN formating and returns it back
     * @param mobileIdentifier - MSISDN to be normalized
     */
    public normalize (mobileIdentifier) {
        if (mobileIdentifier) {
            return mobileIdentifier.toString().replace(/^(0049|0|\+49)/, '49');
        }
    }

    /**
     * checks if an identifier is msisdn according to the following rule:
     * it's considered as msisdn if it started with “0”, “+49”
     * or “49 and its length is more than 9 digits”
     * @param identifier - the identifier to be checked
     */
    public checkIfMsisdn (identifier): boolean {
        identifier = identifier.toString();

        return (identifier.startsWith('+49') || identifier.startsWith('0') || (identifier.startsWith('49') && identifier.length > 9));
    }
    /**
     * checks if an identifier is landline according to the following regex:
     * '^(0{1,2}49|[+]49|0|49)(?!1)([0-9]{9,15})$'
     * @param identifier - the identifier to be checked
     */
    public checkIfLandline (identifier): boolean {
        identifier = identifier.toString();
        const isLandLineRegex = new RegExp(regex.landLineRegex);

        return isLandLineRegex.test(identifier);
    }

    /** cloneArrayOfObjects */
    public cloneArrayOfObjects (oldArray: any[]) {

        const newArray: any[] = [];

        oldArray.forEach((item) => {

            newArray.push(this.clone(item));
        });

        return newArray;
    }
    /**
     * Checks if the old array is reused or update
     * and returns the array with its status
     * @param oldArray - the old Answers Array saved in the service
     * @param newArray - the new Answers Array recently updated by the user
     */
    public checkAnswersArrayStatus (oldArray, newArray) {
        newArray.forEach((newItem) => {
            // set the default status to update until updated later in the execution
            newItem.flag = SECURITY_QUESTION_TYPES.update;
            oldArray.find((oldItem) => {
                if (oldItem.value == newItem.value) {
                    if (newItem.answer == oldItem.answer) {
                        // reuse (same question and answer)
                        newItem.flag = SECURITY_QUESTION_TYPES.reuse;
                    }

                    return oldItem;

                }
            });
        });

        return newArray;
    }

    /**
     * takes a flag, checks if it was string return the boolean equivelant for it
     * @param flag - the flag to be checked
     */
    public parseStringToBoolean (flag: string | boolean) {
        if (typeof flag == 'string') {
            return flag == 'true';
        } else {
            return flag;
        }
    }
    /**
     * trims all spaces if the trim all flag is set, else, it trims the leading and trailing spaces only
     * @param value - string to be trimmed
     * @param trimMode - flag to trim all or to trim only the trailing and
     */
    public trim (value: string, trimMode?: string): any {
        switch (trimMode) {
            case TRIM_TYPES.ALL: return value.replace(/\s/g, '');
            case TRIM_TYPES.TRIM: return value.replace(/^[ ]+|[ ]+$/g, '');
            default: return value;
        }
    }

    /** checks if remove method is supported to remove a certain element with a compatible function
     * @param element - the element to be removed
     */
    public removeElementOrRemoveChild (element: Element) {
        if (!('remove' in Element.prototype)) {
            element.parentNode.removeChild(element);
        } else {
            element.remove();
        }
    }

    /** encodeQueryData */
    public encodeQueryData (data) {
        const ret = [];
        ret.push('?');
        for (const d in data) {
            if (data[d]) {
                ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
            }
        }

        return ret.join('&');
    }

    /** convertMsisdnFormatToGSM */
    public convertMsisdnFormatToGSM (msisdn: string) {
        let result;
        if (msisdn) {
            result = msisdn.toString().replace(/^(0049|0|\+49|49)/, 'GSM').trim();
            if (!result.startsWith('GSM')) {
                result = 'GSM' + result;
            }

            return result;
        }
    }

    /** convertMsisdnFormatFromGSM */
    public convertMsisdnFormatFromGSM (msisdn: string) {
        if (msisdn) {
            return msisdn.toString().replace('GSM', '0');
        }
    }

    /** covert MSISDN to format 0XXXXXXXX */
    public convertMsisdnFormat (msisdn: string) {
        if (msisdn) {
            return msisdn.toString().replace(/^(0049|49|\+49)/, '0');
        }
    }

    /**
     * Remove # and query params from step name
     */
    public cleanStepName (stepName: string) {
        return stepName.split('#')[0].split('?')[0];
    }
}
