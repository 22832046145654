import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

/** ConfigWhitelistService */
@Injectable()
export class ConfigWhitelistService {

  private whitelist: any;
public constructor (private httpClient: HttpClient) { }

}
