import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AuthenticationService } from '@vfde-fe/ngx-lib/services/authentication';
import { CoreAuthenticationService } from '@vfde-fe/ngx-lib/services/core-authentication';
import { CoreConfigurationsLoaderService } from '@vfde-fe/ngx-lib/services/core-configurations-loader';
import { CoreConfigurationsLoaderModule } from '@vfde-fe/ngx-lib/services/core-configurations-loader';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_PROVIDERS } from './app.providers';
import { ConfigLoaderService } from './common/utils/config-loader.service';
import { CustomTranslateLoader } from './common/utils/custom-translate-loader.component';
import { HTTPClientInterceptor } from './common/utils/http-client-interceptor.service';
import { AuthModule } from './common/shared/auth.module';
import { PluginsModule } from './common/shared/plugins.module';
import { HelperModule } from '@vfde-fe/ngx-lib/services/helper-utils';
import { TrackingModule } from '@vfde-fe/ngx-lib/plugins/tracking';
import { RoutesUtilsModule } from '@vfde-fe/ngx-lib/services/routes-utils';
import { SpinnerModule } from '@vfde-fe/ngx-lib/components/spinner';
import { CustomErrorHandlerModule } from '@vfde-fe/ngx-lib/services/custom-error-handler';
import { TransactionHeaderLightModule } from '@vfde-fe/ngx-lib/components/transaction-header-light';
import { NavigationModule } from '@vfde-fe/ngx-lib/components/navigation';
import { SeamlessInfoModule } from '@vfde-fe/ngx-lib/components/new-seamless-info';
import { UnauthorizedComponent } from './common/shared-components/unauthorized/unauthorized.component';
import { GeneralErrorComponent } from './common/shared-components/general-error/general-error.component';
import { NotificationModule } from '@vfde-fe/ngx-lib/components/new-notification';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import {APP_BASE_HREF, PlatformLocation} from '@angular/common';

/** createTranslateLoader */
export function createTranslateLoader (http: HttpClient, configLoaderService: ConfigLoaderService) {
    return new CustomTranslateLoader(http, configLoaderService, environment.configPath + 'i18n/', '.json');
}
/** configLoaderFactory */
export function configLoaderFactory (config: ConfigLoaderService) {
    return () => config.load();
}
/** AppModule */
@NgModule({
    declarations: [
        AppComponent,
        UnauthorizedComponent,
        GeneralErrorComponent,
    ],
    imports: [
        AuthModule.forRoot({
            provide: CoreAuthenticationService,
            useClass: AuthenticationService,
        }),
        CoreConfigurationsLoaderModule.forRoot({
            provide: CoreConfigurationsLoaderService,
            useClass: ConfigLoaderService,
        }),
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        NgxPageScrollCoreModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient, CoreConfigurationsLoaderService],
            },
        }),
        PluginsModule.forRoot(),
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        HelperModule,
        TrackingModule,
        RoutesUtilsModule,
        SpinnerModule,
        CustomErrorHandlerModule,
        TransactionHeaderLightModule,
        NgxPageScrollCoreModule,
        SeamlessInfoModule,
        NavigationModule,
        NotificationModule,
        NgIdleKeepaliveModule.forRoot(),
],
    providers: [
        { provide: APP_BASE_HREF,  useFactory: BaseURLFactory, deps: [PlatformLocation]},
        APP_PROVIDERS,
        {
            provide: APP_INITIALIZER,
            useFactory: configLoaderFactory,
            deps: [CoreConfigurationsLoaderService],
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: HTTPClientInterceptor, multi: true},
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
/** Base URl Factory */
export function BaseURLFactory (platformLocation: PlatformLocation): string {
    const baseHrefFromDOM = platformLocation.getBaseHrefFromDOM();
    if (baseHrefFromDOM.indexOf('/business/hotspot/') != -1) {
        
        return '/business/hotspot/account'
    }
    const isBusinessBaseUrl = baseHrefFromDOM.match(new RegExp('\/business\/([A-Za-z0-9]*)\/account'));

    return isBusinessBaseUrl ? baseHrefFromDOM : '/meinvodafone/account';
}
