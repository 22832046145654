<navigation>
<div *ngIf="accessLevel">
    <transaction-header-light [defaultTitleKey]="pageTitle" [accessLevel]="accessLevel"></transaction-header-light>
</div>
<div class="standard-form">
    <notification [notificationDetails]="notificationDetails" (redirectTo)="openOverlay()">
  </notification>
</div>
</navigation>
<seamless-info [(openTray)]="trayOpened"></seamless-info>
