import { NgModule, ModuleWithProviders } from '@angular/core';
// import { CommonModule } from '@angular/common';
import { TrackingInterceptor } from '@vfde-fe/ngx-lib/plugins/tracking';
import { TrackingService } from '@vfde-fe/ngx-lib/plugins/tracking';
// import { AuthModule } from './auth.module';

/** modules  */
@NgModule({
    imports: [],
})

/** PluginsModule */
export class PluginsModule {
    /** forRoot */
      public static forRoot (): ModuleWithProviders<any> {
          return {
              ngModule: PluginsModule,
              providers: [
                  TrackingInterceptor,
                  TrackingService,
              ],
          };
      }
  }
