import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimService } from '@vfde-fe/ngx-lib/services/sim';
import { CommonCacheService } from '@vfde-fe/ngx-lib/services/common-cache';
import { UserCacheService } from '@vfde-fe/ngx-lib/services/user-cache';
// import { ContractCacheService } from '@vfde-fe/ngx-lib/services/contract-cache';
// import { SubscriptionCacheService } from '@vfde-fe/ngx-lib/services/subscription-cache';
// import { SimCacheService } from '@vfde-fe/ngx-lib/services/sim-cache';
import { AuthenciatoinServiceProvider } from '@vfde-fe/ngx-lib/services/core-authentication';
import { CoreAuthenticationService } from '@vfde-fe/ngx-lib/services/core-authentication';
/** modules */
@NgModule({
    imports: [CommonModule],
    providers: [
        CoreAuthenticationService,
        SimService,
    ],
})
/** AuthModule */
export class AuthModule {
    /** forRoot */
    public static forRoot (obj?: AuthenciatoinServiceProvider): ModuleWithProviders<any> {
        return {
            ngModule: AuthModule,
            providers: [
                CommonCacheService,
                UserCacheService,
                // ContractCacheService,
                // SubscriptionCacheService,
                // SimCacheService,
                obj ? obj : CoreAuthenticationService,
            ],
        };
    }
}
